import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Menu from "../components/menu"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allPocketArticle.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All pocket articles" />
        <Menu />
        <h1>Saved in my Pocket</h1>
        {posts.map(({ node }) => {
          return (
            <article key={node.id}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <a href={node.url} target='_blank' rel='noopener noreferrer'>
                    {node.title}
                  </a>
                </h3>
                {node.image && <div><img src={node.image.src} alt={node.title} /></div>}
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
        <Bio />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allPocketArticle(sort: {fields: time_added, order: DESC}, limit: 10) {
      edges {
        node {
          excerpt
          title
          url
          id
          image {
            src
            width
            height
          }
        }
      }
    }
  }
`
